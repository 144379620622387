import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import config from "../src/Services/config.json";

const Slider = ({ slides }) => {
  const IMAGE_PARTS = 4;
  const AUTOCHANGE_TIME = 4000;

  const [state, setState] = useState({
    activeSlide: -1,
    prevSlide: -1,
    sliderReady: false,
  });

  const changeTO = useRef(null);

  useEffect(() => {
    runAutochangeTO();

    setTimeout(() => {
      setState({ activeSlide: 0, sliderReady: true });
    }, 0);

    return () => {
      window.clearTimeout(changeTO.current);
    };
  }, []);

  const runAutochangeTO = () => {
    changeTO.current = setTimeout(() => {
      changeSlides(1);
      runAutochangeTO();
    }, AUTOCHANGE_TIME);
  };

  const changeSlides = (change) => {
    window.clearTimeout(changeTO.current);
    const { length } = slides;
    const prevSlide = state.activeSlide;
    let activeSlide = prevSlide + change;
    if (activeSlide < 0) activeSlide = length - 1;
    if (activeSlide >= length) activeSlide = 0;
    setState({ activeSlide, prevSlide });
  };

  const { activeSlide, prevSlide, sliderReady } = state;

  return (
    <div className={`slider ${sliderReady ? "s--ready" : ""}`}>
      <div className="slider__slides">
        {slides.map((slide, index) => (
          <div
            className={`slider__slide ${
              activeSlide === index ? "s--active" : ""
            } ${prevSlide === index ? "s--prev" : ""}`}
            key={slide.title}
          >
            <div className="slider__slide-content">
              <h2 className="slider__slide-heading">
                {slide?.title?.split(" ").map((l, i) => (
                  <React.Fragment key={i}>
                    <span>{l}</span>
                    {i < slide.title.split(" ").length - 1 && (
                      <span>&nbsp;</span>
                    )}
                  </React.Fragment>
                ))}
              </h2>
              <p className="slider__slide-subheading" style={{ margin: "0" }}>
                {slide.sub_title}
              </p>
              <Link
                target="_blank"
                to={
                  slide?.button_url !== "" || slide?.button_url !== "null"
                    ? slide?.button_url
                    : "#"
                }
                className="ud-btn banner-btn fw500 btn-thm mt10 mt0-xs"
              >
                {slide?.button_text ? slide?.button_text : "Get started"}
                <i className="fal fa-arrow-right-long"></i>
              </Link>
            </div>
            <div className="slider__slide-parts">
              {[...Array(IMAGE_PARTS).fill()].map((_, i) => (
                <div className="slider__slide-part" key={i}>
                  <div
                    className="slider__slide-part-inner"
                    style={{
                      backgroundImage: `url(${
                        config?.path + slide?.banner_image
                      })`,
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="slider__control" onClick={() => changeSlides(-1)} />
      <div
        className="slider__control slider__control--right"
        onClick={() => changeSlides(1)}
      />
    </div>
  );
};

export default Slider;
