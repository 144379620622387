import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import config from "../../../../Services/config.json";

function SocialEvent({ details }) {
  const [socialEventDetails, setSocialEventDetails] = useState({});
  const loaderRef = useRef("");

  useEffect(() => {
    setSocialEventDetails(details);
    if (details && Object.keys(details).length === 0) {
      loaderRef.current.className = "preloader";
    } else {
      loaderRef.current.classList.remove("preloader");
    }
  }, [details]);

  return (
    <>
      <div ref={loaderRef}></div>
      <div className="body_content">
        <section
          className="breadcumb-section3 p-0"
          style={{
            backgroundImage: `url(${config.path}${details?.banner_image})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcumb-style1">
                  <h2 className="title text-white text-center">
                    {socialEventDetails?.name}
                  </h2>
                  <p className="fz15 text-white">
                    {socialEventDetails?.sub_title}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="agent-single pt0 ">
          <div className="container">
            <div
              className="row wow fadeInUp"
              data-wow-delay="300ms"
              style={{
                visibility: "visible",
                animationDelay: "300ms",
                animationName: "fadeInUp",
              }}
            >
              <div className="col-lg-12 pr40 pr20-lg">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="agent-single-details mt30 pb30 bdrb1">
                      <p
                        className="text"
                        dangerouslySetInnerHTML={{
                          __html: socialEventDetails?.description,
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ paddingTop: "60px" }}>
              <div className="col-12">
                <div className="main-title2">
                  <h2 className="title text-center pb-4">
                    {socialEventDetails?.title}
                  </h2>
                </div>
              </div>
              <div
                className="col-lg-12 wow fadeInUp"
                data-wow-delay="300ms"
                style={{
                  visibility: "visible",
                  animationDelay: "300ms",
                  animationName: "fadeInUp",
                }}
              >
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-house"
                    role="tabpanel"
                    aria-labelledby="pills-house-tab"
                  >
                    <div className="row">
                      {socialEventDetails?.event?.map((list, i) => {
                        return (
                          <div className="col-md-6 col-xl-4" key={i}>
                            <div
                              className="listing-style6"
                              style={{ minHeight: "200px" }}
                            >
                              <div className="list-thumb">
                                <img
                                  className="w-100"
                                  style={{ minHeight: "250px" }}
                                  src={config?.path + list?.banner_image}
                                  alt=""
                                />
                              </div>
                              <div className="list-content">
                                <h6 className="list-title">
                                  <Link to={`/event/${list?.slug}`}>
                                    {list?.title}
                                  </Link>
                                </h6>
                                <p className="list-text">{list?.sub_title}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Link className="scrollToHome" to="#">
          <i className="fas fa-angle-up"></i>
        </Link>
      </div>
    </>
  );
}

export default SocialEvent;
