import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { GET_API } from "../../../Services/api";
import config from "../../../Services/config.json";
import Noimage from "../../../assets/images/Noimage.png";
function Gallery() {
  const loaderRef = useRef("");

  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    getGalleryDetails();
  }, []);

  const getGalleryDetails = async () => {
    const loader = loaderRef?.current;
    loader.className = "preloader";
    const galleryDetails = await GET_API("gallery");
    if (galleryDetails?.data?.status === 200) {
      setGallery(galleryDetails?.data?.data);
      loaderRef.current.classList.remove("preloader");
    }
  };
  return (
    <>
      <div ref={loaderRef}></div>
      <div className="body_content">
        <section className="pt-120">
          <div className=" container navtab-style1 mb-4 mb-lg-5 mt50">
            <h2
              className="sp-lg-title pt-30 pb-80 text-center"
              style={{ marginBottom: "40px" }}
            >
              Our Gallery
            </h2>

            <nav>
              <div className="nav nav-tabs mb20" id="nav-tab2" role="tablist">
                {gallery?.map((galleryDetail, i) => {
                  return (
                    <button
                      className={`nav-link fw600 ${
                        galleryDetail?.slug === "wedding" ? "active" : ""
                      }`}
                      id="nav-item1-tab"
                      data-bs-toggle="tab"
                      data-bs-target={"#" + galleryDetail?.slug}
                      type="button"
                      role="tab"
                      key={i}
                      aria-controls="nav-item1"
                      aria-selected="true"
                    >
                      {galleryDetail?.name}
                    </button>
                  );
                })}
              </div>
            </nav>

            <div className="tab-content" id="nav-tabContent">
              {gallery?.map((galleryItem, i) => {
                return (
                  <div
                    className={`tab-pane fade fz15 ${
                      i === 0 ? "active show" : ""
                    }`}
                    id={galleryItem?.slug}
                    role="tabpanel"
                    aria-labelledby="nav-item2-tab"
                    key={i}
                  >
                    <div className="row">
                      {galleryItem?.gallery?.length === 0 ? (
                        <img src={Noimage} alt="no_image" height={420} />
                      ) : (
                        galleryItem?.gallery.map((details, i) => {
                          return (
                            <div className="col-md-4" key={i}>
                              <div className="sp-img-content">
                                <Link
                                  className=" preview-img-2 sp-img mb10"
                                  to={`/gallery/portfolioview/${details?.slug}`}
                                >
                                  <img
                                    className="w-100"
                                    src={config.path + details?.banner_image}
                                    alt="2.jpg"
                                    height={262}
                                  />
                                </Link>
                                <Link
                                  to={`/gallery/portfolioview/${details?.slug}`}
                                  className="all-tag"
                                >
                                  view more
                                </Link>
                              </div>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Gallery;
