import React, { useContext, useEffect, useRef, useState } from "react";
import logo from "../../assets/images/logo.svg";
import mobileDarkNav from "../../assets/images/mobile-dark-nav-icon.svg";
import { Link, useLocation, useParams } from "react-router-dom";
import { GET_API } from "../../Services/api";
import SettingsProvider from "../API/SettingsProvider";
import config from "../../Services/config.json";
import SidebarItem from "./SidebarItem";
// import sidebarList from "../Layouts/Sidebar.json";

function Header() {
  const location = useLocation();
  const pathName = location?.pathname;
  const [eventMenu, setEventMenu] = useState([]);
  const [isNavBar, setIsNavBar] = useState(false);
  const settingsInfo = useContext(SettingsProvider);
  const sideBarRef = useRef(null);
  const openSidebarRef = useRef(null);
  const closeSidebarRef = useRef(null);
  const params = useParams();

  useEffect(() => {
    getDynamicEventMenu();
  }, []);

  useEffect(() => {
    closeSidebarRef.current.style.display = "none";
    openSidebarRef.current.style.display = "block";
    sideBarRef.current.classList.remove("visible");
  }, [pathName]);

  const getDynamicEventMenu = async () => {
    const eventMenu = await GET_API("eventCategoryWithEvents");
    if (eventMenu?.data?.status === 200) {
      setEventMenu(eventMenu?.data?.data);
    }
  };

  const handleNavBar = () => {
    if (isNavBar) {
      sideBarRef.current.classList.remove("visible");
      closeSidebarRef.current.style.display = "none";
      openSidebarRef.current.style.display = "block";
    } else {
      sideBarRef.current.classList.add("visible");
      closeSidebarRef.current.style.display = "block";
      openSidebarRef.current.style.display = "none";
    }
    setIsNavBar((prev) => !prev);
  };

  const sidebarList = [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "About Us",
      path: "/about",
    },
    {
      title: "Events",
      event: eventMenu,
    },
    {
      title: "Gallery",
      path: "/gallery",
    },
    {
      title: "Blog",
      path: "/blog",
    },
    {
      title: "Contact Us",
      path: "/contact",
    },
  ];

  return (
    <>
      <div
        className="wrapper ovh"
        style={{ position: "absolute", zIndex: "1000" }}
      >
        <div className="preloader d-none"></div>
        <header
          className={`header-nav ${
            pathName === "/gallery" ||
            pathName === "/blog" ||
            params?.slug === "corporate-events" ||
            params?.slug === "more-events"
              ? "nav-homepage-style1"
              : "nav-homepage-style"
          } at-home2 stricky main-menu slideIn animated`}
        >
          <nav>
            <div className="container posr">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="logos mr35">
                      <Link to="/" className="header-logo logo1">
                        <img
                          src={config?.path + settingsInfo?.logo}
                          style={{
                            height: "60px",
                            width: "150px",
                          }}
                          alt="Header Logo"
                        />
                      </Link>
                      <Link to="/" className="header-logo logo2">
                        <img
                          src={config?.path + settingsInfo?.logo}
                          alt="Header Logo"
                          style={{
                            height: "60px",
                            width: "150px",
                          }}
                        />
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-auto">
                  <div className="d-flex align-items-center">
                    <ul
                      id="respMenu"
                      className="ace-responsive-menu"
                      data-menu-style="horizontal"
                    >
                      <li className="visible_list">
                        <Link to="/" className="list-item">
                          <span className="title">Home</span>
                        </Link>
                      </li>
                      <li className="visible_list">
                        <Link to="/about" className="list-item">
                          <span className="title">About Us</span>
                        </Link>
                      </li>

                      <li className="visible_list">
                        <Link className="list-item">
                          <span className="title">Events</span>
                        </Link>
                        <ul>
                          {eventMenu?.map((menuName, i) => {
                            return (
                              <React.Fragment key={i}>
                                <li>
                                  <Link
                                    to={"eventCategory/" + menuName?.slug}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span className="title">
                                      {menuName?.name}
                                    </span>
                                    <i className="bi bi-caret-right"></i>
                                  </Link>
                                  <ul className="sub-menu">
                                    {menuName?.event?.map((subMenu, i) => {
                                      return (
                                        <li key={i}>
                                          {/* <Link to={`event/${subMenu?.slug}`}> */}
                                          <Link
                                            to={
                                              "eventCategory/" +
                                              menuName?.slug +
                                              "/" +
                                              subMenu?.slug
                                            }
                                          >
                                            {subMenu?.title}
                                          </Link>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </li>
                              </React.Fragment>
                            );
                          })}
                        </ul>
                      </li>

                      <li className="visible_list">
                        <Link to="/gallery" className="list-item">
                          <span className="title">Gallery</span>
                        </Link>
                      </li>
                      <li className="visible_list">
                        <Link to="/blog" className="list-item">
                          <span className="title">Blog</span>
                        </Link>
                      </li>
                      <li className="visible_list">
                        <Link className="list-item" to="/contact">
                          <span className="title">Contact Us</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>

        {/* Mobile Nav */}
        <div id="page" className="mobilie_header_nav stylehome1">
          <div className="mobile-menu">
            <div
              className="header innerpage-style"
              style={{ position: "fixed" }}
            >
              <div className="menu_and_widgets">
                <div className="mobile_menu_bar d-flex justify-content-between align-items-center">
                  <Link
                    className="menubar"
                    style={{ display: "flex" }}
                    onClick={() => {
                      handleNavBar();
                    }}
                  >
                    <img
                      className="icon-default"
                      src={mobileDarkNav}
                      alt=""
                      ref={openSidebarRef}
                    />
                    <i
                      className="bi bi-x-circle icon-hover"
                      style={{ fontSize: "22px" }}
                      ref={closeSidebarRef}
                    ></i>
                  </Link>
                  <Link className="mobile_logo">
                    <img src={logo} alt="" />
                  </Link>
                  <Link to="#"></Link>
                </div>
              </div>
            </div>
          </div>

          {/*.mobile-menu */}
          {/* <nav id="menu" className="">
            <ul>
              <li>
                <Link>Home</Link>
              </li>
              <li>
                <Link>About Us</Link>
              </li>

              <li>
                <span>Events</span>
                <ul>
                  <li>
                    <Link>
                      <span className="title">Corporate Events</span>
                    </Link>
                    <ul>
                      <li>
                        <Link>Exhibition and trade shows</Link>
                      </li>
                      <li>
                        <Link>Inaugural ceremony</Link>
                      </li>
                      <li>
                        <Link>Product and brand launch</Link>
                      </li>
                      <li>
                        <Link>Roadshows and promotions</Link>
                      </li>
                      <li>
                        <Link>Award nights and annual meetings</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link>
                      <span className="title">Social Events</span>
                    </Link>
                    <ul>
                      <li>
                        <Link>Wedding Functions</Link>
                      </li>
                      <li>
                        <Link>Birthday Functions</Link>
                      </li>
                      <li>
                        <Link>Anniversary</Link>
                      </li>
                      <li>
                        <Link>Theme based parties</Link>
                      </li>
                      <li>
                        <Link>House warming</Link>
                      </li>
                      <li>
                        <Link>Baby shower function</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link>
                      <span className="title">More</span>
                    </Link>
                    <ul>
                      <li>
                        <Link>Music concert</Link>
                      </li>
                      <li>
                        <Link>Music & Cultural and Entertainment Events</Link>
                      </li>
                      <li>
                        <Link>Government and political events</Link>
                      </li>
                      <li>
                        <Link>Convocations</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <Link>Gallery</Link>
              </li>
              <li>
                <Link>Blog</Link>
              </li>

              <li className="px-3 mobile-menu-btn">
                <Link className="ud-btn btn-thm text-white">
                  Contact Us<i className="fal fa-arrow-right-long"></i>
                </Link>
              </li>
            </ul>
          </nav> */}
          {/* {isNavBar && ( */}
          <div className="sidebar" ref={sideBarRef}>
            {sidebarList.map((item, index) => (
              <SidebarItem key={index} item={item} />
            ))}
          </div>
          {/* )} */}
        </div>
      </div>
    </>
  );
}

export default Header;
