import React from "react";
import { Link } from "react-router-dom";

const Pagination = ({ currentPage, lastPage, paginationClick }) => {
  const renderPageLinks = () => {
    const pages = [];

    for (let page = 1; page <= lastPage; page++) {
      pages.push(
        <li
          key={page}
          className={`page-item ${currentPage === page ? "active" : ""}`}
        >
          <Link className="page-link" onClick={() => paginationClick(page)}>
            {page}
            {currentPage === page && <span className="sr-only">(current)</span>}
          </Link>
        </li>
      );
    }

    return pages;
  };

  return (
    <div className="row pt-4">
      <div className="mbp_pagination text-center">
        <ul className="page_navigation">
          <li className="page-item">
            <Link
              className="page-link"
              onClick={() => paginationClick(currentPage - 1)}
            >
              <span className="fas fa-angle-left"></span>
            </Link>
          </li>
          {renderPageLinks()}
          <li className="page-item">
            <Link
              className="page-link"
              onClick={() => paginationClick(currentPage + 1)}
            >
              <span className="fas fa-angle-right"></span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Pagination;
