import Joi from "joi";

class ValidationClass {
  validateEnquiryForm(data) {
    const privilegeSchema = Joi.object({
      name: Joi.string()
        .pattern(new RegExp("^[a-zA-Z][a-zA-Z' ']*$"))
        .max(150)
        .required()
        .messages({
          "string.empty": `Please Enter name`,
          "string.max": `Max length is 150 character`,
          "string.base": `Please Enter valid name`,
          "string.pattern.base": `Please Enter valid name`,
        }),

      phone_number: Joi.string()
        .pattern(new RegExp("^[0-9]*$"))
        .length(10)
        .required()
        .messages({
          "string.empty": `Please Enter phone number`,
          "string.base": `Please Enter valid phone number`,
          "string.length": `Mobile number must be 10 digits`,
          "string.pattern.base": `Please Enter valid phone number`,
        }),

      type: Joi.string().required().messages({
        "string.empty": `Please select your profession`,
        "string.base": `Please select your profession`,
        "string.pattern.base": `Please select valid profession`,
      }),

      message: Joi.string()
        .pattern(new RegExp("^[a-zA-Z0-9][a-zA-Z0-9' ']*$"))
        .max(150)
        .allow(null, "")
        .messages({
          "string.base": `Please select Valid Type name`,
          "string.pattern.base": `Please select valid Type`,
          "string.max": `Max length is 150 character`,
        }),
    }).unknown(true);
    return privilegeSchema.validate(data);
  }

  ContatctFormFromAboutPage(data) {
    const contactFormSchema = Joi.object({
      date_time: Joi.string().max(150).required().messages({
        "string.empty": `Please Select date and time`,
        "string.max": `Max length is 150 character`,
        "string.base": `Please Select date and time`,
        "string.pattern.base": `Please Select date and time`,
      }),

      name: Joi.string()
        .pattern(new RegExp("^[a-zA-Z][a-zA-Z' ']*$"))
        .max(150)
        .required()
        .messages({
          "string.empty": `Please Enter name`,
          "string.max": `Max length is 150 character`,
          "string.base": `Please Enter valid name`,
          "string.pattern.base": `Please Enter valid name`,
        }),

      phone: Joi.string()
        .regex(/^[0-9]{10}$/)
        .required()
        .messages({
          "string.pattern.base":
            "Invalid mobile number. Please enter a 10-digit number.",
          "any.required": "Mobile number is required.",
        }),

      email: Joi.string()
        .email({ tlds: { allow: false } })
        .required()
        .messages({
          "string.email":
            "Invalid email address. Please provide a valid email.",
          "any.required": "Email is required. Please provide an email address.",
        }),

      message: Joi.string()
        .pattern(new RegExp("^[a-zA-Z0-9][a-zA-Z0-9' ']*$"))
        .max(150)
        .allow(null, "")
        .messages({
          "string.base": `Please eeter any messages`,
          "string.pattern.base": `Please eeter any messages`,
          "string.max": `Max length is 150 character`,
        }),
    }).unknown(true);
    return contactFormSchema.validate(data);
  }

  ContatctFormFromEventPage(data) {
    const contactFormSchema = Joi.object({
      name: Joi.string()
        .pattern(new RegExp("^[a-zA-Z][a-zA-Z' ']*$"))
        .max(150)
        .required()
        .messages({
          "string.empty": `Please Enter name`,
          "string.max": `Max length is 150 character`,
          "string.base": `Please Enter valid name`,
          "string.pattern.base": `Please Enter valid name`,
        }),

      phone: Joi.string()
        .regex(/^[0-9]{10}$/)
        .required()
        .messages({
          "string.pattern.base":
            "Invalid mobile number. Please enter a 10-digit number.",
          "any.required": "Mobile number is required.",
        }),

      message: Joi.string()
        .pattern(new RegExp("^[a-zA-Z0-9][a-zA-Z0-9' ']*$"))
        .max(150)
        .allow(null, "")
        .messages({
          "string.base": `Please eeter any messages`,
          "string.pattern.base": `Please eeter any messages`,
          "string.max": `Max length is 150 character`,
        }),
    }).unknown(true);
    return contactFormSchema.validate(data);
  }

  RatingAndReview(data) {
    const ratingSchema = Joi.object({
      email: Joi.string()
        .email({ tlds: { allow: false } })
        .required()
        .messages({
          "string.email":
            "Invalid email address. Please provide a valid email.",
          "any.required": "Email is required. Please provide an email address.",
        }),

      name: Joi.string()
        .pattern(new RegExp("^[a-zA-Z][a-zA-Z' ']*$"))
        .max(150)
        .required()
        .messages({
          "string.empty": `Please Enter Name`,
          "string.max": `Max length is 150 character`,
          "string.base": `Please Enter valid Name`,
          "string.pattern.base": `Please Enter valid Name`,
        }),

      title: Joi.string()
        .pattern(new RegExp("^[a-zA-Z][a-zA-Z' ']*$"))
        .max(150)
        .required()
        .messages({
          "string.empty": `Please Enter Title`,
          "string.max": `Max length is 150 character`,
          "string.base": `Please Enter valid Title`,
          "string.pattern.base": `Please Enter valid Title`,
        }),

      rating: Joi.number().integer().positive().required().messages({
        "number.base": `Please select your rating`,
        "number.required": `Please select your rating`,
      }),

      review: Joi.string().max(200).required().messages({
        "string.empty": `Please Enter Review`,
        "string.base": `Please enter any messages`,
        "string.pattern.base": `Please eeter any messages`,
        "string.max": `Max length is 200 character`,
      }),
    }).unknown(true);
    return ratingSchema.validate(data);
  }
}
const Validation = new ValidationClass();
export default Validation;
