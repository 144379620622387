import React, { useContext, useEffect, useState } from "react";
import logo from "../../assets/images/logo.svg";
import { Link } from "react-router-dom";
import { GET_API } from "../../Services/api";
import config from "../../Services/config.json";
import SettingsProvider from "../API/SettingsProvider";

function Footer() {
  const [footer, setFooter] = useState({});
  const settingsInfo = useContext(SettingsProvider);
  useEffect(() => {
    getFooterDetails();
  }, []);

  const getFooterDetails = async () => {
    const contactDetails = await GET_API("contactus");
    if (contactDetails?.data?.status === 200) {
      setFooter(contactDetails?.data?.data);
    }
  };

  const contact = footer?.contact;
  const serviceCategory = footer?.eventCat;

  return (
    <>
      <section className="footer-style1 at-home4 pt60 pb-0">
        <div className="container">
          <div className="row gray-bdrb1 pb30 mb60">
            <div className="col-sm-5">
              <div className="footer-widget light-style text-center text-sm-start">
                <Link className="footer-logo">
                  <img
                    src={config?.path + settingsInfo?.logo}
                    alt="Header Logo"
                    style={{
                      height: "60px",
                      width: "150px",
                    }}
                  />
                </Link>
              </div>
            </div>
            <div className="col-sm-7">
              <div className="social-widget text-center text-sm-end">
                <div className="social-style1 light-style2">
                  <Link className="me-2 fw600 fz15 text-white" to="">
                    Follow us
                  </Link>
                  <Link target="_blank" to={contact?.facebook_link}>
                    <i className="fab fa-facebook-f list-inline-item"></i>
                  </Link>
                  <Link target="_blank" to={contact?.twitter_link}>
                    <i className="fab fa-twitter list-inline-item"></i>
                  </Link>
                  <Link target="_blank" to={contact?.instagram_link}>
                    <i className="fab fa-instagram list-inline-item"></i>
                  </Link>
                  <Link target="_blank" to={contact?.linkedin_link}>
                    <i className="fab fa-linkedin-in list-inline-item"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="row justify-content-between">
                <div className="col-auto">
                  <div className="link-style1 light-style at-home9 mb-3">
                    <h6 className="mb25 text-white">Useful Links</h6>
                    <div className="link-list">
                      <Link to="/about">About</Link>
                      <Link to="/gallery">Gallery</Link>
                      <Link to="/blog">Our Blogs</Link>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="link-style1 light-style at-home9 mb-3">
                    <h6 className="mb25 text-white">Services</h6>
                    <div className="link-list">
                      {serviceCategory?.map((category, i) => {
                        return (
                          <Link to={"eventCategory/" + category?.slug} key={i}>
                            {category?.name}
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="link-style1 light-style at-home9 mb-3">
                    <h6 className="mb25 text-white">Our Location</h6>
                    <ul className="ps-0">
                      <li>
                        <div
                          dangerouslySetInnerHTML={{ __html: contact?.address }}
                          className="text1"
                        ></div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 offset-lg-2">
              <div className="footer-widget light-style mb-4 mb-lg-5">
                <div className="row mb-4">
                  <div className="col-auto">
                    <div className="contact-info">
                      <p
                        className="text text-white"
                        style={{ fontWeight: "600" }}
                      >
                        Contact
                      </p>
                      <h6 className="info-phone">
                        <Link to="tel:+919539818364">
                          {contact?.mobile_number}
                        </Link>
                      </h6>
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="contact-info">
                      <p
                        className="text text-white"
                        style={{ fontWeight: "600" }}
                      >
                        Email
                      </p>
                      <h6 className="info-mail">
                        <Link to="mailto:cameoevents1@gmail.com">
                          {contact?.email}
                        </Link>
                      </h6>
                    </div>
                  </div>
                </div>
                {/* <div className="footer-widget light-style mb30">
                  <div className="mailchimp-widget">
                    <div className="mailchimp-style2">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Your Email"
                      />
                      <button className="ud-btn btn-thm" type="submit">
                        Subscribe
                      </button>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="container gray-bdrt1 py-4">
          <div className="row">
            <div className="col-sm-6">
              <div className="text-center text-lg-start">
                <p className="copyright-text ff-heading text-white">
                  © 2023, Cameo Events. All right reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Link className="scrollToHome" href="#">
        <i className="fas fa-angle-up"></i>
      </Link>
    </>
  );
}

export default Footer;
