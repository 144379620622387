import React from "react";

const VideoPlayer = ({ videoUrl }) => {
  return (
    <video width="100%" height="500" controls autoPlay>
      <source src="./assets/images/EventReel.mp4" type="video/mp4" />
    </video>
  );
};

export default VideoPlayer;
