import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GET_API } from "../../../Services/api";
import CorporateEventSubmenu from "../Events/Submenu/CorporateEventSubmenu";
import SocialEventSubmenu from "../Events/Submenu/SocialEventSubmenu";
import MoreEventSubmenu from "../Events/Submenu/MoreEventSubmenu";
function Event() {
  const params = useParams();
  const [eventDetails, setEventDetails] = useState({});

  useEffect(() => {
    getEventDetails();
  }, [params]);

  const getEventDetails = async () => {
    try {
      const event = await GET_API(`eventBySlug/${params?.submenu}`);
      if (event?.data?.status === 200) {
        setEventDetails(event?.data?.data);
      }
    } catch (error) {}
  };

  return (
    <>
      {params?.slug === "corporate-events" ? (
        <CorporateEventSubmenu details={eventDetails} />
      ) : params?.slug === "social-event" ? (
        <SocialEventSubmenu details={eventDetails} />
      ) : (
        <MoreEventSubmenu details={eventDetails} />
      )}
    </>
  );
}

export default Event;
