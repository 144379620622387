import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function SidebarItem({ item }) {
  const [open, setOpen] = useState(false);

  if (item.event) {
    return (
      <div className={open ? "sidebar-item open" : "sidebar-item"}>
        <div
          className="sidebar-title"
          onClick={() => setOpen(!open)}
          style={{ cursor: "pointer" }}
        >
          <span>{item.title}</span>
          <i className="bi-chevron-down toggle-btn"></i>
        </div>

        <div className="sidebar-content">
          {item?.event?.map((child, index) => (
            <React.Fragment key={index}>
              <SubChildren subChild={child} />
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <Link to={item?.path} className="sidebar-item plain">
        {item.title}
      </Link>
    );
  }

  function SubChildren({ subChild }) {
    const [isOPenSubChildren, setIsOPenSubChildren] = useState(false);
    if (subChild.event) {
      return (
        <div
          className={isOPenSubChildren ? "sidebar-item open" : "sidebar-item"}
        >
          <div className="sidebar-title" style={{ cursor: "pointer" }}>
            <Link to={`eventCategory/${subChild?.slug}`}>{subChild.title}</Link>
            <i
              className="bi-chevron-down toggle-btn"
              onClick={() => setIsOPenSubChildren(!isOPenSubChildren)}
            ></i>
          </div>
          <div className="sidebar-content">
            {subChild?.event?.map((subChildren, i) => (
              <React.Fragment key={i}>
                <Link
                  to={`eventCategory/${subChild?.slug}/${subChildren?.slug}`}
                >
                  {subChildren?.title}
                </Link>
              </React.Fragment>
            ))}
          </div>
        </div>
      );
    } else {
      return (
        <Link to={item?.path} className="sidebar-item plain">
          {item.title}
        </Link>
      );
    }
  }
}
