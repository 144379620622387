import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CorporateEvents from "../../Pages/Events/Corporate/CorporateEvents";
import SocialEvents from "../../Pages/Events/Social/SocialEvent";
import MoreEvents from "../../Pages/Events/More/OtherEvents";
import { GET_API } from "../../../Services/api";

function Index() {
  const location = useParams();
  const params = useParams();
  const [details, setDetails] = useState({});

  useEffect(() => {
    getCorporateEventDetails();
  }, [params]);

  const getCorporateEventDetails = async () => {
    try {
      const details = await GET_API(`eventcatBySlug/${params.slug}`);
      if (details?.data?.status === 200) {
        setDetails(details?.data?.data);
      }
    } catch (error) {
      return;
    }
  };

  return (
    <>
      {location?.slug === "corporate-events" ? (
        <CorporateEvents details={details} />
      ) : location?.slug === "social-event" ? (
        <SocialEvents details={details} />
      ) : (
        <MoreEvents details={details} />
      )}
    </>
  );
}

export default Index;
