import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { GET_API } from "../../../Services/api";
import config from "../../../Services/config.json";

function Portfolio() {
  const location = useParams();
  const loaderRef = useRef("");

  const [portfolio, setPortfolio] = useState({});

  useEffect(() => {
    getPortfolioDetails();
  }, []);

  const getPortfolioDetails = async () => {
    const loader = loaderRef?.current;
    loader.className = "preloader";
    const portfolio = await GET_API(`gallery/${location?.id}`);
    if (portfolio?.data?.status === 200) {
      setPortfolio(portfolio?.data?.data);
      loaderRef.current.classList.remove("preloader");
    }
  };

  return (
    <>
      <div ref={loaderRef}></div>
      <div className="body_content">
        <section
          className="breadcumb-section-gallery p-0"
          style={{
            backgroundImage: `url(${config.path}${portfolio?.banner_image})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcumb-style1">
                  <h2 className="title text-white text-center">
                    {portfolio?.name}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pt60 pb90 bgc-f7">
          <div className="container">
            <div className="row mb30 mt30 wow fadeInUp" data-wow-delay="300ms">
              <div className="col-12">
                <div className="row">
                  {portfolio?.images?.map((image, i) => {
                    return (
                      <div className="col-md-4" key={i}>
                        <div className="sp-img-content">
                          <Link
                            className="popup-img preview-img-2 sp-img mb10"
                            to={config.path + image?.image_path}
                          >
                            <img
                              className="w-100"
                              src={config.path + image?.image_path}
                              alt="2.jpg"
                            />
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Portfolio;
