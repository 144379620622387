import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import config from "../../../../Services/config.json";
import SpinnerEffect2 from "../../../../assets/images/about/element-11.png";
import SpinnerEffect1 from "../../../../assets/images/about/element-12.png";
import ArrowEffect from "../../../../assets/images/about/element-13.png";
import Validation from "../../../Validation";
import { POST_API } from "../../../../Services/api";

function Corporate({ details }) {
  const [corporateEventDetails, setCorporateEventDetails] = useState({});
  const loaderRef = useRef("");
  const [buttonDisabled, setButtonDisabled] = useState("");
  const [errors, setErrors] = useState({});
  const [formResponseMessage, setFormResponseMessage] = useState("");
  const [contactForm, setContactForm] = useState({
    name: "",
    phone: "",
    message: "",
  });

  useEffect(() => {
    setCorporateEventDetails(details);
    if (Object.keys(details).length === 0) {
      loaderRef.current.className = "preloader";
    } else {
      loaderRef.current.classList.remove("preloader");
    }
  }, [details]);

  const handleFormChange = (e) => {
    const { name, value } = e?.target;
    setContactForm({ ...contactForm, [name]: value });
  };

  const handleContactFormSubmit = async (e) => {
    e?.preventDefault();
    const isValid = Validation.ContatctFormFromEventPage(contactForm);
    try {
      if (isValid && isValid?.error) {
        setButtonDisabled(false);
        setErrors({
          [isValid?.error?.details[0].path[0]]:
            isValid.error.details[0].message,
        });
      } else {
        setErrors({});
      }
      const addForm = await POST_API("contactusForm", contactForm);
      if (addForm?.data?.status === 200) {
        setFormResponseMessage(addForm?.data?.message);
        setContactForm({
          name: "",
          phone: "",
          message: "",
        });
      }
    } catch (error) {
      setFormResponseMessage("Failed to add details...!");
    }
  };

  return (
    <>
      <div ref={loaderRef}></div>
      <div className="body_content">
        <section className="agent-single pt80">
          <div className="cta-agent bgc-dark mx-auto maxw1600 pt100 pb100 bdrs12 position-relative mx20-lg">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-7">
                  <div className="agent-single d-sm-flex align-items-center">
                    <div className="single-contant ml30 ml0-xs">
                      <h2 className="title mb-0 text-white ptb-4">
                        {corporateEventDetails?.title}
                      </h2>

                      <p className="fz15 text-white">
                        {corporateEventDetails?.sub_title}
                      </p>
                    </div>
                  </div>
                  <div className="img-box-12 position-relative d-none d-xl-block">
                    <img
                      className="img-1 spin-right"
                      src={SpinnerEffect1}
                      alt=""
                    />
                    <img className="img-2 bounce-x" src={ArrowEffect} alt="" />
                    <img
                      className="img-3 bounce-y"
                      src={SpinnerEffect2}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row wow fadeInUp" data-wow-delay="300ms">
              <div className="col-lg-8 pr40 pr20-lg">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="agent-single-details mt30 pb30 bdrb1">
                      <p
                        className="text"
                        dangerouslySetInnerHTML={{
                          __html: corporateEventDetails?.description,
                        }}
                      ></p>
                      {/* 
                      <div className="agent-single-accordion">
                        <div
                          className="accordion accordion-flush"
                          id="accordionFlushExample"
                        >
                          <div className="accordion-item">
                            <div
                              id="flush-collapseOne"
                              className="accordion-collapse collapse"
                              aria-labelledby="flush-headingOne"
                              data-bs-parent="#accordionFlushExample"
                            ></div>
                            <h2
                              className="accordion-header"
                              id="flush-headingOne"
                            >
                              <button
                                className="accordion-button p-0 collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne"
                                aria-expanded="false"
                                aria-controls="flush-collapseOne"
                              >
                                Show more
                              </button>
                            </h2>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="agent-single-form home8-contact-form default-box-shadow1 bdrs12 bdr1 p30 mb30-md bgc-white position-relative">
                  <h4 className="form-title text-center mb25">Contact Form</h4>
                  <form className="form-style1">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="mb20">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Your Name"
                            name="name"
                            onChange={(e) => {
                              handleFormChange(e);
                            }}
                            value={contactForm?.name}
                          />
                          {errors.name ? (
                            <div className="validation-error-label">
                              {errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="mb20">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Phone"
                            name="phone"
                            onChange={(e) => {
                              handleFormChange(e);
                            }}
                            value={contactForm?.phone}
                          />
                          {errors.phone ? (
                            <div className="validation-error-label">
                              {errors.phone}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="mb10">
                          <textarea
                            cols="30"
                            rows="4"
                            name="message"
                            placeholder="There are many variations of passages."
                            onChange={(e) => {
                              handleFormChange(e);
                            }}
                            value={contactForm?.message}
                          ></textarea>
                          {errors.message ? (
                            <div className="validation-error-label">
                              {errors.message}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="d-grid">
                          <button
                            onClick={(e) => {
                              handleContactFormSubmit(e);
                            }}
                            type="submit"
                            className="ud-btn btn-thm mb15"
                            disabled={buttonDisabled}
                          >
                            Send Message
                            <i className="fal fa-arrow-right-long"></i>
                          </button>
                          <Link
                            className="ud-btn btn-white2"
                            to="tel:+919539818364"
                          >
                            Call<i className="fal fa-arrow-right-long"></i>
                          </Link>
                          <div
                            className="validation-error-label"
                            style={{ padding: "6px" }}
                          >
                            {formResponseMessage}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="our-agents bgc-white pt-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 pb30">
                <div className="breadcumb-style1">
                  <h2 className="title">{corporateEventDetails?.title}</h2>
                </div>
              </div>
            </div>

            <div
              className="row wow fadeInUp"
              data-wow-delay="300ms"
              style={{
                visibility: "visible",
                animationDelay: "300ms",
                animationName: "fadeInUp",
              }}
            >
              {corporateEventDetails?.event?.map((service, i) => {
                return (
                  <div className="col-md-6 col-lg-4" key={i}>
                    <div className="agency-style1 p30 bdrs12 bdr1 mb30">
                      <div className="agency-img">
                        <img
                          style={{ height: "250px" }}
                          className="w-100"
                          src={config.path + service?.banner_image}
                          alt=""
                        />
                      </div>
                      <div className="agency-details pt40">
                        <h6 className="agency-title mb-3 text-center">
                          {service?.title}
                        </h6>

                        <div className="d-grid">
                          <Link
                            to={"/event/" + service?.slug}
                            className="ud-btn btn-white2"
                          >
                            View more<i className="fal fa-arrow-right-long"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        <Link className="scrollToHome" to="#">
          <i className="fas fa-angle-up"></i>
        </Link>
      </div>
    </>
  );
}

export default Corporate;
